<template>
  <div>
    <div class="row" v-if="!loading_menu">
      <div class="col-md-12">
        <router-link :to="{ name: 'admin.academy.courses.show' }"><i class="lni lni-arrow-left"></i> Back To Courses</router-link>
        <h4>Course #{{ course.sort }} - {{ course.title }}</h4>
        <hr>
      </div>
      <div class="col-3">
        <ul class="academy-nav">
          <li v-for="(week, i) in weeks" :key="`week-${i}`">
            <router-link
              :to="{ name: 'admin.academy.weeks.show', params: { week_id: week.id } }"
              :class="week.id == current.id ? 'link-active' : ''"
              >#{{ week.sort }} {{ week.title }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="col-9">
        <div v-if="!loading_content">
          <div class="dropdown float-right">
            <button
              class="btn btn-info dropdown-toggle btn-sm"
              type="button"
              id="dropdownMenuButton-section"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Actions
            </button>
            <div
              class="dropdown-menu"
            >
              <router-link
                class="dropdown-item"
                :to="{ name: 'admin.academy.weeks.edit' }"
                >Edit Week</router-link
              >
              <a class="dropdown-item" href="#" @click.prevent="destroy"
                >Delete Week</a
              >
            </div>
          </div>
          <h4>Week #{{ current.sort }} {{ current.title }}</h4>
          <p>
            <span class="mr-3"><strong>Videos:</strong> {{ current.videos }}</span>
            <span><strong>Price:</strong> USD {{ current.cost }}</span>
          </p>
          <div v-html="current.about"></div>
          <hr />
          <video-list />
        </div>
        <loading v-if="loading_content" />
      </div>
    </div>
    <loading v-if="loading_menu" />
  </div>
</template>

<script>
import VideoList from "./videos/Index";

export default {
  components: {
    VideoList,
  },

  mounted() {
    this.fetchMenu();
    this.fetchTitle()
  },

  data() {
    return {
      loading_menu: true,
      loading_content: true,
      weeks: [],
      current: {},
      course: {}
    };
  },

  methods: {
    fetchMenu() {
      this.loading_menu = true;

      this.$axios.get(`/api/v1/admin/academy/${this.$route.params.course_id}/weeks`).then(response => {
        this.weeks = response.data.weeks;
        this.loading_menu = false;
        this.fetchContent()
      })
    },

    fetchTitle() {
      this.$axios
        .get(`/api/v1/admin/academy/${this.$route.params.id}/courses/${this.$route.params.course_id}`)
        .then((response) => {
          this.course = response.data.course;
        });
    },

    fetchContent() {
      this.loading_content = true;

      this.$axios
        .get(`/api/v1/admin/academy/${this.$route.params.course_id}/weeks/${this.$route.params.week_id}`)
        .then((response) => {
          this.current = response.data.week;
          this.loading_content = false;
        });
    },

    destroy() {
      this.loading_content = true;

      this.$axios
        .delete(`/api/v1/admin/academy/${this.$route.params.id}/weeks/${this.$route.params.week_id}`)
        .then(() => {
          this.loading_content = false;

          this.$router.push({ name: "admin.academy.courses.show"});
        });
    },
  },

  computed: {
    week_id() {
      return this.$route.params.week_id;
    },
  },

  watch: {
    week_id() {
      this.fetchContent();
    },
  },
};
</script>
